import {useState} from 'react';
import {DateTime} from 'luxon';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {bookTeakEntity} from '../../../../api';
import {CARD} from '../../../../constants/cards';
import {enableCard, updateErrorCard, updateSuccessCard} from '../../../../store/cards';
import Icon from '../../../Common/Icon';
import {loadDashboardData} from '../../../../store/dashboard';
import {loadNextBookings} from '../../../../store/bookingsOverview/bookings';
import {useHistory} from 'react-router';
import {ROUTES} from '../../../../constants/routes';
import Button from '../../../Common/Button';
import {TEAK_ENTITY_NAMES} from './../../../../constants/teakEntityNames';

function ConfirmBookingConflicts(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const data = useSelector((state) => state.cards.data);
	const conflictsList = useSelector((state) => state.entitiesBooking.recurringBooking.recurringConflicts);
	const attendeeList = useSelector((state) => state.entitiesBooking.attendeeList);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const recurringRule = useSelector((state) => state.entitiesBooking.recurringBooking.rrule);
	const selectedStartTime = useSelector((state) => state.entitiesBooking.selectedStartTime);
	const selectedEndTime = useSelector((state) => state.entitiesBooking.selectedEndTime);
	const name = data.entityData?.name;
	const entityId = data.entityData?._id;
	const meetingName = useSelector((state) => state.entitiesBooking.meetingName) || t('yourBooking.meetingIn', {name});
	const isHybridVirtualMeeting = useSelector((state) => state.entitiesBooking.onlineMeetingEnabled);
	const teakTypeDiscriminator = data.entityData?.__t;
	const approvalRequired = data.entityData?.approvalRequired?.enabled;
	const [isLoading, setIsLoading] = useState(false);

	let conflictDates = [];
	conflictsList.forEach((conflict, index) => {
		conflictDates.push(
			<div key={index} className="date-item">
				<Icon cssClass={'icon-red'} iconName={'times-circle'} type={'far'}></Icon>
				<p>{DateTime.fromISO(conflict.s).setZone(selectedOffice.timezone).toLocaleString()}</p>
			</div>,
		);
	});

	let conflictsLength = conflictDates.length;

	const confirmBookingHandleClick = () => {
		if (isLoading) return;
		setIsLoading(true);
		let startTime = DateTime.fromISO(selectedStartTime).toUTC().toISO();
		let endTime = DateTime.fromISO(selectedEndTime).toUTC().toISO();
		bookTeakEntity(
			entityId,
			startTime,
			endTime,
			recurringRule,
			false,
			attendeeList ? attendeeList.map((item) => ({member: item.id})) : undefined,
			undefined,
			undefined,
			undefined,
			teakTypeDiscriminator === TEAK_ENTITY_NAMES.ROOM ? meetingName : null,
			isHybridVirtualMeeting,
		)
			.then((response) => {
				const successKey = approvalRequired ? 'yourBooking.bookingRequestedTitle' : 'yourBooking.successText';
				const successText = approvalRequired ? 'yourBooking.bookingRequestedDescription' : null;
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, successKey, successText));
				dispatch(loadDashboardData(true));
				dispatch(loadNextBookings(true));
				history.push(ROUTES.DEFAULT);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'errorMessages.general', error));
				setIsLoading(false);
			});
	};

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="exclamation-recurring-conflicts">
				<p className="success-text header">{t('yourBooking.recurringBookings.confirmConflicts')}</p>
				<p className="conflicts-length">
					<Trans i18nKey="yourBooking.recurringBookings.nbOfConflictsExists" conflictsLength={conflictsLength}>
						<span className="conflictsLength">{{conflictsLength}}%</span> conflict(s) exists
					</Trans>
				</p>
				<div className="conflict-dates-wrapper">{conflictDates}</div>
			</div>
			<div className="buttons-wrapper">
				<Button
					variant={'primary'}
					height={'regular'}
					width={'full'}
					clickHandler={confirmBookingHandleClick}
					translationKey={'common.buttons.confirm'}
					loading={isLoading}
				/>

				<Button variant={'secondary'} height={'regular'} width={'full'} clickHandler={closeCard} translationKey={'common.cancelButton'} />
			</div>
		</div>
	);
}

export default ConfirmBookingConflicts;
