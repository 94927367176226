import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Icon from '../../Common/Icon';
import {enableCard} from '../../../store/cards';
import Button from '../../Common/Button';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';
import {Typography} from '../../Common/Typography';
import CustomCheckbox from '../../Common/CustomCheckbox';
import {useTranslation} from 'react-i18next';
import {hideFromBroadcast} from '../../../api/offices';

function BroadcastNotificationCard(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const notificationList = useSelector((state) => state.cards.data);
	const [notification, setNotification] = useState();
	const [checkboxChecked, setCheckboxChecked] = useState(false);
	const repeatType = notification?.data?.repeatType;
	const header = notification?.data?.content[0]?.header;
	const body = notification?.data?.content[0].body;
	const icon = notification?.data?.icon;
	const colors = colorBasedOnTheme();

	useEffect(() => {
		setNotification({data: notificationList[0], index: 0});
	}, [notificationList]);

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	function moveToNextNotification() {
		if (repeatType === 'entering_app' && checkboxChecked) {
			hideFromBroadcast(notification?.data?.officeId, notification?.data?.id);
		}
		if (notification.index === notificationList.length - 1) {
			closeCard();
			return;
		}
		const nextNotificationIndex = notification.index + 1;
		setNotification({data: notificationList[nextNotificationIndex], index: nextNotificationIndex});
	}

	const handleCheckboxChange = () => {
		setCheckboxChecked((prev) => !prev);
	};

	return (
		<div className="card broadcast-notification">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-wrapper">
				<div className="broadcast-notification-icon">
					{<Icon cssClass={'icon-purple'} iconColor={colors.colorMainTheme} iconName={icon} />}
				</div>
				<div className="broadcast-notification-content">
					<p className="header">{header}</p>
					<p className="text">{body}</p>
				</div>
				<div className="buttons-wrapper">
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						clickHandler={moveToNextNotification}
						translationKey={'common.okButton'}
					/>
					{repeatType === 'entering_app' && (
						<div className="checkbox-wrapper" onClick={handleCheckboxChange}>
							<CustomCheckbox checked={checkboxChecked} />
							<Typography component="span">{t('notification.doNotShowMessageAgain')}</Typography>
						</div>
					)}
				</div>
				{notificationList?.length > 1 && (
					<p className="broadcast-notification-counter">{`${notification?.index + 1}/${notificationList?.length}`}</p>
				)}
			</div>
		</div>
	);
}

export default BroadcastNotificationCard;
