import axios from 'axios';
import {DateTime} from 'luxon';
import apiUrl from './index';

const source = axios.CancelToken.source();
let config = {cancelToken: source.token};

const pathUrls = {
	teakEntity: '/biro/teak/entity',
	book: '/book',
	recurringConflicts: '/recurringConflicts',
	reportProblem: '/reportIssue',
	calendarView: '/calendarView',
	getPhotos: '/photos',
	findBookingsOfEntities: '/findBookingsOfEntities',
	findOwnEntities: '/findOwnEntities',
	updateSharingRules: '/updateSharingRules',
};
export async function getTeakEntity(entityId, identifierCode) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakEntity + '/' + entityId;
	if (identifierCode) requestUrl = hostUrl + pathUrls.teakEntity + '?identifier=' + identifierCode;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function bookTeakEntity(
	teakEntityId,
	startTime,
	endTime,
	rrule,
	checkInNow,
	attendees,
	amount = null,
	intent = null,
	colleagueId = null,
	meetingName = null,
	onlineMeetingEnabled = false
) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakEntity + '/' + teakEntityId + pathUrls.book;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				start: startTime,
				end: endTime,
				checkInNow: checkInNow,
				rrule: rrule,
				attendees: attendees,
				payment: {
					amountPaid: parseInt(amount),
					intent: intent,
				},
				...(colleagueId && {colleague: colleagueId}),
				meetingName: meetingName,
				onlineMeetingEnabled
			},
		},
		config,
	);
}

export async function checkConflictsForRecurringBooking(deskId, startTime, endTime, rrule) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakEntity + '/' + deskId + pathUrls.recurringConflicts;

	if (startTime !== undefined && endTime !== undefined) {
		const formattedStartTime = DateTime.fromISO(startTime).toUTC().toISO();
		const formattedEndTime = DateTime.fromISO(endTime).toUTC().toISO();

		return axios(
			{
				method: 'post',
				url: requestUrl,
				data: {
					start: formattedStartTime,
					end: formattedEndTime,
					rrule: rrule,
				},
			},
			config,
		);
	}
}

export async function getAllEntities(areaId, officeId, teakType) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakEntity + apiUrl.path.findMany;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				filters: {
					office: officeId,
					area: areaId,
					teakType: teakType,
				},
				allTeakEntities: true,
			},
		},
		config,
	);
}
export async function getManyEntities(areaId, startTime, endTime, officeId, teakType, includeFavorites, maxOccupancy, memberId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakEntity + apiUrl.path.findMany;

	let includeTeakBookingInRange;
	if (startTime !== undefined && endTime !== undefined) {
		includeTeakBookingInRange = {
			start: DateTime.fromISO(startTime).toUTC().toISO(),
			end: DateTime.fromISO(endTime).toUTC().toISO(),
		};
	}
	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				filters: {
					office: officeId,
					area: areaId,
					teakType: teakType,
				},
				includeFavorites,
				includeTeakBookingInRange: includeTeakBookingInRange,
				maxOccupancy,
				colleague: memberId,
			},
		},
		config,
	);
}

export async function reportProblemForTeakEntity(deskId, description) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakEntity + '/' + deskId + pathUrls.reportProblem;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				description: description,
			},
		},
		config,
	);
}

/**
 *
 * @param {string} entityId = id of entity you want bookings for
 * @param {object} range = range object that has start and end => both are ISO strings representing times in UTC timezone.
 * @returns
 */
export async function getBookingsForCalendarView(entityId, range) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakEntity + '/' + entityId + pathUrls.calendarView;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				range,
			},
		},
		config,
	);
}

/**
 *
 * @param {string} entityId = id of entity you want to get photos for
 * @param {string} index = index of photo
 * @returns
 */
export async function getPhotoForEntity(entityId, index) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakEntity + '/' + entityId + pathUrls.getPhotos;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				index,
				variation: 400,
			},
			responseType: 'arraybuffer',
		},
		config,
	);
}

/**
 *
 * @param {Array<Id>} teakEntities = array of ids of entities that need to be checked for bookings
 * @param {ISO} start = start of time range in which to get bookings
 * @param {ISO} end = end of time range in which to get bookings
 * @returns
 */
export async function findBookingsOfEntities(teakEntities, start, end) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakEntity + pathUrls.findBookingsOfEntities;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				teakEntities,
				start,
				end,
			},
		},
		config,
	);
}

/**
 *
 * @param {ID} office = office id where to query the own entities
 * @returns list of entities where the member is owner
 */
export async function findOwnEntities(office) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakEntity + pathUrls.findOwnEntities;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				office,
			},
		},
		config,
	);
}

/**
 *
 * @param {string} entityId = id of entity you want to update the sharing rules
 * @param {ISO} start = start of time range in which rule is active
 * @param {ISO} end = end of time range in which rule is active
 * @param {string[]} listOfWhitelistedMembers = list of emails of members that are whitelisted for this entity
 * @returns
 */
export async function addNewSharingRules(entityId, start, end, listOfWhitelistedMembers, organization, office) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakEntity + '/' + entityId + pathUrls.updateSharingRules;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				start,
				end,
				listOfWhitelistedMembers,
				organization,
				office,
			},
		},
		config,
	);
}
