import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {createTheme} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import {ThemeProvider} from '@material-ui/styles';
import {DateTime} from 'luxon';
import TimePicker from 'rc-time-picker';

import Icon from '../Icon';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';

/**
 * @param {string} dateTime = date time value for datepicker and timepicker
 * @param {function} changeTime = function for changing time of the timepicker and the date of the datepicker element
 * @param {string} timeFormat = format to show time
 * @param {boolean} timeFormat12hrs = show in 12 or 24 hour time format
 * @param {boolean} disabled = value for disabling the datepicker
 * @param {boolean} disableFuture = value for disable future dates
 * @param {boolean} disablePast = value for disable past dates
 * @param {string} minDate = min selectable date
 * @param {string} maxDate = max selectable date
 * @param {number} minuteStep = interval to show the minutes = default is 15 ( 00, 15, 30, 45 )
 * @param {string} timezone = timezone for showing/setting dates/times
 * @param {function} handleFocus = handler for focusing on date or time picker
 * @param {string} customTimePickerPlacement = position to show the opened time picker = default is topLeft ( other options: topRight, bottomLeft, bottomRight);
 * @param {string} inputLabelKey = translation key for the label shown on top of both inputs
 * @param {function} disabledHours = returns array of hours that should be disabled
 * @param {function} disabledMinutes = returns array of hours that should be disabled
 * @param {boolean} props.onlyDate = show only the date picker without time picker
 * @param {string} datePickerCss = cssClass used for datepicker wrapper div
 * @param {string} disabledTimes = disables only the visual interaction with the start and end date of the time picker
 * @returns
 */

function SingleDateTimePicker({
	dateTime,
	changeTime,
	timeFormat,
	timeFormat12hrs,
	disabled,
	disableFuture,
	disablePast = false,
	minDate,
	maxDate = false,
	minuteStep = 15,
	timezone,
	handleFocus,
	customTimePickerPlacement,
	inputLabelKey,
	disabledHours,
	disabledMinutes,
	onlyDate = false,
	datePickerCss = '',
	disabledTimes=false
}) {
	const {t} = useTranslation();
	const color = colorBasedOnTheme();
	const [focusedInput, setFocusedInput] = useState(false);
	const dateFormat = useSelector((state) => state.auth.data.dateFormat);

	const materialTheme = createTheme({
		overrides: {
			MuiPickersToolbar: {
				toolbar: {
					backgroundColor: color.colorMainTheme,
				},
			},
		},
	});

	let formatString = dateFormat === 'dd/LL/yyyy' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

	return (
		<div className={`date-time-input-wrapper ${disabled ? 'date-time-input-wrapper-disabled' : ''}`}>
			<label className="date-time-input-label">{t(inputLabelKey)}</label>
			<div className="date-time-input-content">
				<div className={`datepicker-wrapper ${datePickerCss}`}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<ThemeProvider theme={materialTheme}>
							<DatePicker
								value={dateTime}
								onChange={(value) => changeTime(DateTime.fromISO(value.format()).setZone(timezone).toISO())}
								format={formatString}
								disabled={disabled}
								disablePast={disablePast}
								error={false}
								helperText={null}
								minDate={minDate}
								maxDate={maxDate ? maxDate : undefined}
								disableFuture={disableFuture}
								onOpen={handleFocus}
							/>
						</ThemeProvider>
						<Icon cssClass={color.datePickerIcon} iconName="calendar-alt" />
					</MuiPickersUtilsProvider>
				</div>
				{!onlyDate && (
					<div className={`timepicker-wrapper ${focusedInput && 'focused-input'}`}>
						<TimePicker
							showSecond={false}
							value={dateTime}
							onChange={(e) => {
								changeTime(DateTime.fromISO(e.toISOString()).setZone(timezone).toISO());
							}}
							disabledHours={disabledHours}
							disabledMinutes={disabledMinutes}
							format={timeFormat}
							use12Hours={timeFormat12hrs}
							inputReadOnly
							allowEmpty={false}
							getPopupContainer={(node) => node}
							minuteStep={minuteStep}
							disabled={disabled || disabledTimes}
							onFocus={handleFocus}
							placement={customTimePickerPlacement}
							onOpen={() => setFocusedInput(true)}
							onClose={() => setFocusedInput(false)}
						/>
						<Icon cssClass={color.datePickerIcon} iconName={focusedInput ? 'angle-up' : 'angle-down'} />
					</div>
				)}
			</div>
		</div>
	);
}

export default SingleDateTimePicker;
