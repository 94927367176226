import {useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import moment from 'moment';

import {ROUTES} from '../../../constants/routes';
import {enableBottomMenu, getOfficeBasedOnId, getTeakTypeObj} from '../../../store/auth';
import {
	changeEntityBookingEndTime,
	changeEntityBookingStartTime,
	updateSelectedEntity,
	changeMeetingName,
	changeSelectedTeakType,
} from '../../../store/bookingProcess/entitiesBooking';
import SecondaryHeader from '../../Common/Header/SecondaryHeader';
import Button from '../../Common/Button';
import DateTimePicker from '../../Common/DateTimePicker/DateTimePicker';
import PrimaryInput from '../../Common/Inputs/PrimaryInput';
import AddPeople from '../../Booking/ConfirmBooking/AddPeople';
import {TEAK_ENTITY_NAMES} from '../../../constants/teakEntityNames';
import {FeaturesChipList} from '../../Common/Chip/FeaturesChipList';
import {roundTimeQuarterHour} from '../../../functions/roundTimeToNextQuarter';
import {limit} from '../../../constants/limit';
import {DEFAULT_TEAK_ENTITY_TYPES} from '../../../constants/teakTypeNames';
import {getTeakTypeName} from '../../Booking/BookingPage/AssetTypes';

const RepeatBooking = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const bookingObject = useSelector((state) => state.entitiesBooking.repeatBookingObject);
	const entity = bookingObject?.entity;
	const selectedStartTime = useSelector((state) => state.entitiesBooking?.selectedStartTime);
	const selectedEndTime = useSelector((state) => state.entitiesBooking?.selectedEndTime);
	const officeOfBooking = entity?.office?._id ? entity?.office?._id : entity?.office;
	const name = entity?.name;
	const areaName = entity?.areaName || entity?.area?.name;
	const officeObject = useSelector(getOfficeBasedOnId(officeOfBooking));
	const teakTypeObj = useSelector(getTeakTypeObj(entity?.teakType));
	const teakTypeDiscriminator = DEFAULT_TEAK_ENTITY_TYPES.includes(entity?.__t) && entity?.__t;
	const timezone = officeObject ? officeObject.timezone : 'UTC';
	const lastMeetingName = useSelector((state) => state.entitiesBooking.meetingName);
	const [meetingName, setMeetingName] = useState(lastMeetingName);
	const [timeError, setTimeError] = useState(null);

	const closeRepeatBooking = () => {
		history.goBack();
	};

	const reserveTeakEntity = () => {
		dispatch(changeSelectedTeakType(teakTypeObj));
		dispatch(updateSelectedEntity({...entity, areaName}));
		history.push(ROUTES.BOOKING_CONFIRM);
	};

	useEffect(() => {
		dispatch(enableBottomMenu(false));

		dispatch(
			changeEntityBookingEndTime(
				DateTime.fromISO(selectedStartTime)
					.plus({hours: entity?.__t === TEAK_ENTITY_NAMES.ROOM ? 1 : 8})
					.toISO(),
			),
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [officeObject]);

	useEffect(() => {
		if (DateTime.fromISO(selectedStartTime).setZone(timezone) < DateTime.now().setZone(timezone)) {
			const newStartTime = roundTimeQuarterHour(DateTime.now().plus({minutes: 10}).toISO());
			dispatch(changeEntityBookingStartTime(DateTime.fromISO(newStartTime).setZone(timezone).toISO()));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!selectedEndTime || !selectedStartTime) return;
		if (DateTime.fromISO(selectedStartTime) < DateTime.now()) {
			setTimeError(<p className="error-text">{t('errorMessages.startInThePast')}</p>);
		} else if (DateTime.fromISO(selectedStartTime) > DateTime.fromISO(selectedEndTime)) {
			setTimeError(<p className="error-text">{t('errorMessages.startAfterEnd')}</p>);
		} else if (timeError) {
			setTimeError(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStartTime, selectedEndTime]);

	const changeStartTime = (newStartTime) => {
		dispatch(changeEntityBookingStartTime(newStartTime));
	};

	const changeEndTime = (newEndTime) => {
		dispatch(changeEntityBookingEndTime(newEndTime));
	};

	return (
		<div className="repeat-booking-page">
			<SecondaryHeader closeButtonClickHandler={closeRepeatBooking} headerTranslationKey={t('common.buttons.book')} />
			<div className="repeat-booking-content">
				<div className="booking-information">
					<p className="title">{t('yourBooking.headers.title')}</p>
					<div className="entity-information">
						<p className="info-entity">
							{getTeakTypeName(teakTypeObj, t)}: <span>{name}</span>
						</p>
						<p className="info-entity">
							{t('common.location')}: <span>{areaName}</span>
						</p>
						<p className="info-entity">
							{t('common.office')}: <span>{officeObject?.name} </span>
						</p>
						<div className="entity-features">
							<p className="info-entity">{t('common.features')}:</p>
							<div className="features-content">{<FeaturesChipList entityData={entity} />}</div>
						</div>
					</div>
				</div>
				{teakTypeDiscriminator === TEAK_ENTITY_NAMES.ROOM && (
					<PrimaryInput
						id={'meetingName'}
						type={'text'}
						cssClass={'meeting-name'}
						labelKey={t('common.meetingName')}
						defaultValue={meetingName}
						maximumCharacterLimit={limit.maximumCharacterLimitMeetingName}
						clickHandler={(value) => {
							setMeetingName(value);
						}}
						placeholderKey={t('common.meeting')}
						handleBlur={() => dispatch(changeMeetingName(meetingName))}
						errorMessage={t('bookingPage.profileCompletionErrorMsg')}
					/>
				)}
				<div className="select-date-time-wrapper">
					<div className="booking-information">
						<p className="title">{t('common.dateTimePicker.header')}</p>
						<DateTimePicker
							startTime={moment(selectedStartTime).tz(timezone)}
							endTime={moment(selectedEndTime).tz(timezone)}
							changeStartTime={(value) => changeStartTime(value)}
							changeEndTime={(value) => changeEndTime(value)}
							timezone={timezone}
							disablePast={true}
							required={true}
							customTimePickerPlacement={'topLeft'}
							error={timeError}
						/>
					</div>
				</div>
				{teakTypeDiscriminator === TEAK_ENTITY_NAMES.ROOM && <AddPeople showAddColleagueLabel={true} />}
			</div>
			<div className="button-fixed-at-bottom-static">
				<Button
					variant={'primary'}
					height={'regular'}
					width={'full'}
					clickHandler={reserveTeakEntity}
					translationKey={'common.saveButton'}
					disabled={timeError}
				/>
			</div>
		</div>
	);
};

export default RepeatBooking;
