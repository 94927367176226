import {useEffect, useRef, useState} from 'react';
import {ROUTES} from '../../constants/routes';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {DateTime} from 'luxon';
import {enableBottomMenu} from '../../store/auth';
import SecondaryHeader from '../Common/Header/SecondaryHeader';
import DaySelection from '../Common/DaySelection/DaySelection';
import {ClipLoader} from 'react-spinners';
import Icon from '../Common/Icon';
import TimeTrackerOverviewItem from './TimeTrackerOverviewItem';
import Button from '../Common/Button';
import {findInRange, getCedarChangeRequests} from '../../api/timeTracker';
import ChangeRequest from '../Dashboard/TimeTracker/ChangeRequest';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {loadCedarOptions, loadChangeRequests} from '../../store/timeTracking';

const TimeTrackerOverview = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const wrapperRef = useRef(null);
	const [activeDate, setActiveDate] = useState(DateTime.now());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [trackedTimeRecords, setTrackedTimeRecords] = useState();
	const changeRequests = useSelector((state) => state.cedar.changeRequests);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const cedarOptions = useSelector((state) => state.cedar.options);
	const selectedOfficeId = selectedOffice?._id;
	const timezone = selectedOffice?.timezone;

	useEffect(async () => {
		dispatch(enableBottomMenu(false));
		dispatch(loadCedarOptions(selectedOffice?._id));
		dispatch(loadChangeRequests(selectedOffice?._id));
		changeActiveDate(activeDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const previousPage = () => {
		history.goBack();
	};
	const handleActivityButton = () => {
		history.push(ROUTES.TIME_TRACKER.MAIN + ROUTES.TIME_TRACKER.ACTIVITY_NEW, {
			actionSheetData: {
				start: {time: activeDate.toISO()},
				end: {time: activeDate.toISO()},
			},
		});
	};

	const changeActiveDate = async (newActiveDate) => {
		setActiveDate(newActiveDate);
		setLoading(true);
		try {
			const startTime = newActiveDate.startOf('day').setZone(timezone);
			const endTime = newActiveDate.endOf('day').setZone(timezone);
			const timeRecordsRequest = await findInRange({office: selectedOfficeId, startTime, endTime});
			const timeRecordsData = timeRecordsRequest.data;
			setTrackedTimeRecords(timeRecordsData);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setError(t('errorMessages.general'));
		}
	};

	return (
		<>
			<div className="time-tracker-overview-page">
				<div className="time-tracker-overview-header">
					<SecondaryHeader
						headerTranslationKey={'timeTracker.overview.header'}
						backButtonClickHandler={previousPage}
						disableBoxShadow={true}
					/>

					<DaySelection activeDate={activeDate} setActiveDate={changeActiveDate} />
				</div>
				<div className="time-tracker-overview-content" ref={wrapperRef}>
					{loading ? (
						<div className="time-tracker-overview-content-no-times">
							<ClipLoader size={70} />
						</div>
					) : (
						<>
							{changeRequests?.length > 0 && cedarOptions?.changeRequestEnabled && (
								<Accordion className={'time-tracker-overview-accordion'}>
									<AccordionSummary expandIcon={<Icon iconName={'chevron-down'} cssClass={'icon-grey'} />}>
										<span className={'time-tracker-overview-accordion-title'}>
											{t('timeTracker.changeRequest.namePlural')} ({changeRequests.length})
										</span>
									</AccordionSummary>
									<AccordionDetails>
										{changeRequests.map((changeRequest) => (
											<ChangeRequest key={changeRequest.id} changeRequest={changeRequest} />
										))}
									</AccordionDetails>
								</Accordion>
							)}

							{trackedTimeRecords?.length > 0 ? (
								<>
									{trackedTimeRecords?.reverse().map((timeRecord, index) => {
										return (
											<TimeTrackerOverviewItem
												key={timeRecord?._id}
												timeRecord={timeRecord}
												changeDateCallback={changeActiveDate}
											/>
										);
									})}
								</>
							) : (
								<div className="time-tracker-overview-content-no-times">
									<Icon iconName={'magnifying-glass'} cssClass={'icon-gray'} />
									<p className="bold">{t('timeTracker.overview.noTimeTrack')}</p>
									<p>{t('timeTracker.overview.noTimeTrackInformation')}</p>
									{error ? <p>{error}</p> : null}
								</div>
							)}
						</>
					)}
				</div>
			</div>
			<div className="button-fixed-at-bottom-static">
				<Button
					variant={'primary'}
					height={'regular'}
					width={'full'}
					loading={loading}
					translationKey={'timeTracker.buttons.add'}
					clickHandler={handleActivityButton}
					disabled={DateTime.fromISO(activeDate) > DateTime.now().endOf('day').setZone(timezone)}
				/>
			</div>
		</>
	);
};
export default TimeTrackerOverview;
