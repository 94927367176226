import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import Icon from '../../Common/Icon';
import {enableCard, updateCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import {CARD} from '../../../constants/cards';
import {ROUTES} from '../../../constants/routes';
import {emptyReportProblemStore, loadIssueReports} from '../../../store/reportProblem';
import {reportBugProblem} from '../../../api';
import {enableBottomMenu} from '../../../store/auth';
import Button from '../../Common/Button';

function SummaryAppReportProblem() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const {path} = useRouteMatch();
	const summary = useSelector((state) => state.reportProblem.enteredSummary);
	const bugReproductionSteps = useSelector((state) => state.reportProblem.reproductionSteps);
	const description = useSelector((state) => state.reportProblem.enteredComment);
	const imageArray = useSelector((state) => state.reportProblem.imageArray);
	const minDescriptionLength = description.length > 1;
	let imagesList = [];
	const selectedEvent = useSelector((state) => state.events.selectedEvent);

	if (imageArray.length > 0) {
		imageArray.forEach((image, index) => {
			imagesList.push(
				<p key={index} className="summary-confirmation-picturename">
					{imageArray[index].name}
				</p>,
			);
		});
	} else {
		imagesList = null;
	}

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	const reportProblem = () => {
		const data = {
			summary,
			bugReproductionSteps,
			description,
			attachments: imageArray,
		};

		dispatch(updateCard(CARD.PLACEHOLDER));
		reportBugProblem(data, selectedEvent ?? false)
			.then(() => {
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'reportProblem.successText'));
				dispatch(loadIssueReports(true));
				dispatch(emptyReportProblemStore());
			})
			.catch((error) => {
				console.log(error);
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'reportProblem.errorText', error));
				dispatch(emptyReportProblemStore());
			});
		dispatch(enableBottomMenu(true));

		if (path.includes('profile')) {
			history.push(ROUTES.PROFILE_PAGE);
		} else {
			history.goBack();
		}
	};

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="summary-confirmation-wrapper">
				<div className="summary-confirmation-header">{t('summaryReportProblemCard.summary')}</div>

				<div className="summary-confirmation-list">
					<div className="summary-confirmation-item">
						<Icon cssClass={'icon-purple'} iconName={'check-circle'} />
						<span>{t('summaryReportProblemCard.issueDescription')}</span>
					</div>
					<div className="summary-confirmation-details">
						<span>{summary}</span>
					</div>

					<div className="summary-confirmation-item">
						<Icon cssClass={'icon-purple'} iconName={'check-circle'} />
						<span>{t('summaryReportProblemCard.bugInformation')}</span>
					</div>
					<div className="summary-confirmation-details">
						<pre>{bugReproductionSteps}</pre>
					</div>

					{!!imagesList && (
						<div className="summary-confirmation-pictures">
							<Icon cssClass={'icon-purple'} iconName={'check-circle'} />
							<span>{t('summaryReportProblemCard.pictures')}</span>
							{imagesList}
						</div>
					)}

					{minDescriptionLength && (
						<>
							<div className="summary-confirmation-item">
								<Icon cssClass={'icon-purple'} iconName={'check-circle'} />
								<span>{t('summaryReportProblemCard.additionalComments')}</span>
							</div>
							<div className="summary-confirmation-details">
								<pre>{description}</pre>
							</div>
						</>
					)}
				</div>

				<div className="buttons-wrapper">
					<Button variant={'primary'} height={'regular'} width={'full'} clickHandler={reportProblem} translationKey={'common.okButton'} />
					<Button variant={'secondary'} height={'regular'} width={'full'} clickHandler={closeCard} translationKey={'common.backButton'} />
				</div>
			</div>
		</div>
	);
}

export default SummaryAppReportProblem;
