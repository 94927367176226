import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ROUTES} from '../../../constants/routes';
import Button from '../../Common/Button';
import {Typography} from '../../Common/Typography';
import {DateTime} from 'luxon';
import {getRangeOfTimeRecords, startTimeRecord, stopTimeRecord} from '../../../api/timeTracker';
import {ClipLoader} from 'react-spinners';
import {Duration} from 'luxon';
import {loadCedarOptions, updateWorkingState} from '../../../store/timeTracking';
//Constants
const TIME_SECOND = 1000;
export const CEDAR_TYPES = {
	work: 'work',
	pause: 'pause',
	stop: 'stop',
	sickLeave: 'sickLeave',
	vacation: 'vacation',
};
let interval;
function TimeTracker() {
	const {t} = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [loadingUI, setLoadingUI] = useState(true);
	const [loadingTimer, setLoadingTimer] = useState(true);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const cedarOptions = useSelector((state) => state.cedar.options);
	const selectedOfficeId = selectedOffice._id;
	const timezone = selectedOffice?.timezone;
	const todaysWeekDayAsNumber = DateTime.now().setZone(timezone).weekday;

	const [duration, setDuration] = useState();
	const [allTimeRecords, setAllTimeRecords] = useState([]);

	const minRestTime = cedarOptions?.minRestTime ?? 28800000; // 8 hours  // not used currently

	const defaultWorkTimePerDay = cedarOptions?.defaultWorkTimePerDay?.find((weekday) => weekday?.weekday === todaysWeekDayAsNumber);
	const maxWorkingHours = defaultWorkTimePerDay?.maxWorkingHours;
	const hourLimitReached = duration > maxWorkingHours;

	let timeForABreakView = {show: false, milliseconds: 0};
	cedarOptions?.pauseRules?.forEach((rule) => {
		const maxWorkTimeWithoutPause = rule?.maxWorkTimeWithoutPause ?? 21600000; // 6 hours
		const pauseTime = rule?.pauseTime ?? 1800000; // 30 minutes

		const workTimeWithoutPause = calculateWorkDurationForLastNMilliseconds(allTimeRecords, maxWorkTimeWithoutPause + pauseTime);
		if (workTimeWithoutPause > maxWorkTimeWithoutPause) {
			timeForABreakView = {
				show: true,
				milliseconds: workTimeWithoutPause,
			};
		}
	});

	const overworking = hourLimitReached || timeForABreakView?.show;

	// Ref to hold the latest allTimeRecords
	const allTimeRecordsRef = useRef(allTimeRecords);

	const [buttonStatus, setButtonStatus] = useState();
	useEffect(async () => {
		setLoadingUI(true);
		dispatch(loadCedarOptions(selectedOffice?._id));
		const timeRecords = await getAllTimeRecords();
		setAllTimeRecords(timeRecords);
		startTimer(timeRecords);
		setLoadingUI(false);
		return () => {
			clearTimeout(loadingTimer);
		};
	}, []);

	useEffect(() => {
		allTimeRecordsRef.current = allTimeRecords;
		let cedarType = CEDAR_TYPES.stop; // Default to 'stop'

		if (!allTimeRecords?.length) {
			setView(cedarType);
			dispatch(updateWorkingState(cedarType));
			return;
		}

		const lastTimeRecord = allTimeRecords[allTimeRecords.length - 1];
		const {end, name} = lastTimeRecord;

		if (end?.time === undefined) {
			cedarType = name;
		}

		setView(cedarType);
		dispatch(updateWorkingState(cedarType));
	}, [allTimeRecords]);

	function handleSeeAll() {
		history.push(ROUTES.TIME_TRACKER.MAIN);
	}

	const startTimer = (timeRecords) => {
		// Execute the code immediately
		const totalWorkDuration = calculateTotalWorkDuration(timeRecords);
		setDuration(totalWorkDuration);

		// Then set up the interval for future executions
		interval = setInterval(() => {
			const totalWorkDuration = calculateTotalWorkDuration(allTimeRecordsRef.current);
			setDuration(totalWorkDuration);
		}, TIME_SECOND);
	};

	const handleProgress = (type) => {
		// eslint-disable-next-line default-case
		switch (type) {
			case CEDAR_TYPES.work: {
				setLoadingTimer(true);
				startTimeRecord({name: CEDAR_TYPES.work, office: selectedOfficeId})
					.then(() => {
						getAllTimeRecords().then((timeRecords) => {
							setAllTimeRecords(timeRecords);
						});
						setLoadingTimer(false);
					})
					.catch((err) => console.log('An error occured while ongoing timer: ', err));
				break;
			}
			case CEDAR_TYPES.pause: {
				setLoadingTimer(true);
				startTimeRecord({name: CEDAR_TYPES.pause, office: selectedOfficeId})
					.then(() => {
						getAllTimeRecords().then((timeRecords) => {
							setAllTimeRecords(timeRecords);
						});
						setLoadingTimer(false);
					})
					.catch((err) => console.log('An error occured while pressing pause:', err));
				break;
			}
			case CEDAR_TYPES.stop: {
				setLoadingTimer(true);
				stopTimeRecord({office: selectedOfficeId})
					.then(() => {
						getAllTimeRecords().then((timeRecords) => {
							setAllTimeRecords(timeRecords);
						});
						setLoadingTimer(false);
					})
					.catch((err) => console.log('An error occured while pressing pause:', err));
				break;
			}

			default: {
				console.log('No state transition found for TimeTracking');
			}
		}
	};

	const setView = (type) => {
		setLoadingTimer(true);
		switch (type) {
			case CEDAR_TYPES.pause: {
				setButtonStatus({work: true, pause: false, stop: true});
				break;
			}
			case CEDAR_TYPES.work: {
				setButtonStatus({work: false, pause: true, stop: true});
				break;
			}
			case CEDAR_TYPES.stop: {
				setButtonStatus({work: true, pause: false, stop: false});
				break;
			}
			default: {
				break;
			}
		}
		setLoadingTimer(false);
	};

	const getAllTimeRecords = async () => {
		const startTime = DateTime.now().startOf('day').setZone(timezone);
		const endTime = DateTime.now().endOf('day').setZone(timezone);
		const todaysTimeRecords = await getRangeOfTimeRecords(selectedOfficeId, startTime, endTime);
		return todaysTimeRecords?.data;
	};

	function calculateTotalWorkDuration(records) {
		if (!records) {
			return 0;
		}

		let totalDuration = Duration.fromObject({hours: 0});

		records.forEach((record) => {
			if (record.name === CEDAR_TYPES.work) {
				const start = DateTime.fromISO(record.start.time);
				const end = record?.end?.time ? DateTime.fromISO(record?.end?.time) : DateTime.now();
				const duration = end.diff(start);
				totalDuration = totalDuration.plus(duration);
			}
		});

		return totalDuration;
	}

	function calculateWorkDurationForLastNMilliseconds(records, milliseconds) {
		if (!records) {
			return 0;
		}

		const now = DateTime.now();
		const cutoffTime = now.minus({milliseconds});

		let totalDuration = Duration.fromObject({hours: 0});

		records.forEach((record) => {
			if (record.name === CEDAR_TYPES.work) {
				const start = DateTime.fromISO(record.start.time);
				const end = record.end && record.end.time ? DateTime.fromISO(record.end.time) : DateTime.now();

				if (end > cutoffTime) {
					const effectiveStart = start > cutoffTime ? start : cutoffTime;
					const duration = end.diff(effectiveStart);
					totalDuration = totalDuration.plus(duration);
				}
			}
		});

		return totalDuration;
	}
	return loadingUI === true ? (
		<div className="ph-item">
			<div className="ph-row">
				<div className="ph-col-12"></div>
			</div>
		</div>
	) : (
		<div className="time-tracker-wrapper">
			<div className="time-tracker-wrapper-header">
				<Typography className="header" variant={'title-large'}>
					{t('dashboard.timeTracker.header')}
				</Typography>

				<div>
					<Button
						cssClass={'show-all'}
						variant={'tertiary'}
						height={'regular'}
						width={'variable'}
						clickHandler={() => handleSeeAll()}
						translationKey={'common.seeAll'}
						iconName={'fas fa-angles-right'}
						iconPlacement={'right'}
					/>
				</div>
			</div>

			<div className={'time-tracker-wrapper-container ' + (overworking && 'overworking')}>
				{loadingTimer === true ? (
					<div className="loading-wrapper">
						<ClipLoader size={100} color="white" />
					</div>
				) : (
					<div>
						{hourLimitReached ? (
							<>
								<p className="timer-header">{t('dashboard.timeTracker.subHeaderHourLimit')}</p>
								<p className="timer-description">{t('dashboard.timeTracker.descriptionHourLimit')}</p>
							</>
						) : timeForABreakView?.show ? (
							<>
								<p className="timer-header">{t('dashboard.timeTracker.subHeaderBreak')}</p>
								<p className="timer-description">
									{t('dashboard.timeTracker.descriptionBreak', {
										count: Math.floor(Duration.fromMillis(timeForABreakView.milliseconds).as('hours')),
									})}
								</p>
							</>
						) : buttonStatus?.pause || buttonStatus?.stop ? (
							<>
								<p className="timer-header">{t('dashboard.timeTracker.subHeaderStart')}</p>
								<p className="timer-description">{t('dashboard.timeTracker.descriptionStart')}</p>
							</>
						) : (
							<>
								<p className="timer-header">{t('dashboard.timeTracker.subHeader')}</p>
								<p className="timer-description">{t('dashboard.timeTracker.description')}</p>
							</>
						)}
						<hr />
						<div className="time-tracker-wrapper-container-buttons">
							<p className="timer">{Duration.fromMillis(duration)?.toFormat('hh:mm:ss')}</p>
							<div>
								{buttonStatus?.work && (
									<Button
										variant={'tertiary'}
										height={'regular'}
										clickHandler={() => handleProgress(CEDAR_TYPES.work)}
										iconName={'fa-regular fa-circle-play'}
										cssClass={'playTimerDashboard'}
										loading={loadingTimer}
									/>
								)}
								{buttonStatus?.pause && (
									<Button
										variant={'tertiary'}
										height={'regular'}
										clickHandler={() => handleProgress(CEDAR_TYPES.pause)}
										iconName={'fa-regular fa-circle-pause'}
										cssClass={'pauseTimerDashboard'}
										loading={loadingTimer}
									/>
								)}
								{buttonStatus?.stop && (
									<Button
										variant={'tertiary'}
										height={'regular'}
										clickHandler={() => handleProgress(CEDAR_TYPES.stop)}
										iconName={'fa-regular fa-circle-stop'}
										cssClass={'resumeTimerDashboard'}
										loading={loadingTimer}
									/>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default TimeTracker;
