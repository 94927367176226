import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {enableCard} from '../../../store/cards';
import Icon from '../../Common/Icon';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import FormattedTime from '../../Common/DateTimePicker/FormattedTime';
import FormattedDate from '../../Common/DateTimePicker/FormattedDate';
import {setChangeRequestHidden} from '../../../api/timeTracker';
import {loadChangeRequests} from '../../../store/timeTracking';

function TimeTrackingChangeRequestCard(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const changeRequest = useSelector((state) => state.cards.data.changeRequest);
	const selectedOfficeTimezone = selectedOffice.timezone;

	const closeCard = async () => {
		dispatch(enableCard(false));
		if (changeRequest?.status === 'accepted' || changeRequest?.status === 'declined') {
			try {
				await setChangeRequestHidden(changeRequest?._id);
				await dispatch(loadChangeRequests(selectedOffice?._id));
			} catch (error) {
				console.log(error);
			}
		}
	};

	const getStatusColor = (status) => {
		switch (status) {
			case 'accepted':
				return '#2ac669';
			case 'pending':
				return '#ffaa45';
			case 'declined':
				return '#db093a';
			default:
				return '#000000';
		}
	};

	return (
		<div className="tt-cr-card-backdrop" onClick={closeCard}>
			<div className="tt-cr-card-content centered-absolute">
				<div className="tt-cr-card-icon-holder">
					<Icon cssClass={'tt-cr-card-icon'} iconName={'fa-regular fa-clock'} />
				</div>
				<div>
					<Typography variant="h6" component="div" className="tt-cr-title">
						<b className={'title'}>
							<b>
								{changeRequest?.action === 'delete'
									? t('timeTracker.changeRequest.deletionRequest')
									: changeRequest?.action === 'add'
										? t('timeTracker.changeRequest.additionRequest')
										: t('timeTracker.changeRequest.name')}
							</b>
						</b>
					</Typography>
					<Box className="tt-cr-details">
						{changeRequest?.action !== 'delete' && (
							<>
								<Typography variant="body1" color="textSecondary">
									<b>{t(`timeTracker.options.${changeRequest?.update?.name}`)}</b>
								</Typography>
								<Typography variant="body1" color="textSecondary">
									<b>{t('timeTracker.changeRequest.from')}: </b>
									<FormattedDate date={changeRequest?.update?.start?.time} timezone={selectedOfficeTimezone} />
									<FormattedTime time={changeRequest?.update.start?.time} timezone={selectedOfficeTimezone} />
								</Typography>
								<Typography variant="body1" color="textSecondary">
									<b>{t('timeTracker.changeRequest.until')}: </b>
									<FormattedDate date={changeRequest?.update?.end?.time} timezone={selectedOfficeTimezone} />
									<FormattedTime time={changeRequest?.update?.end?.time} timezone={selectedOfficeTimezone} />
								</Typography>
							</>
						)}
						<Typography variant="body1" color={getStatusColor(changeRequest?.status)}>
							<b>{t(`timeTracker.changeRequest.status.${changeRequest?.status}`)}</b>
						</Typography>
					</Box>
				</div>
			</div>
		</div>
	);
}

export default TimeTrackingChangeRequestCard;
