import {t} from 'i18next';
import {useDispatch, useSelector} from 'react-redux';
import {BeatLoader} from 'react-spinners';
import {ACTIONSHEET} from '../../constants/actionSheets';
import {updateActionSheet} from '../../store/auth';
import BookingOptionsMenu from './BookingOptionsMenu';
import NewBookingMenu from './NewBookingMenu';
import UnscheduledInvitationsMenu from './UnscheduledInvitationsMenu';
import VisitorMeetingMenu from './VisitorMeetingMenu';
import QRCodeScannerMenu from './QRCodeScannerMenu';
import SessionMenu from './SessionMenu';
import AgendaItemMenu from './AgendaItemMenu';
import ShareAssetMenu from './ShareAssetMenu';
import FavoritesMenu from './FavoritesMenu';
import TimeTrackEditMenu from './TimeTrackEditMenu';
import ChangeRequestMenu from './ChangeRequestMenu';

export const menuMap = {
	bookingOptionsMenu: BookingOptionsMenu,
	newBookingMenu: NewBookingMenu,
	visitorMeetingMenu: VisitorMeetingMenu,
	unscheduledInvitationsMenu: UnscheduledInvitationsMenu,
	qrCodeScannerMenu: QRCodeScannerMenu,
	sessionMenu: SessionMenu,
	agendaItemMenu: AgendaItemMenu,
	shareAssetMenu: ShareAssetMenu,
	favoritesMenu: FavoritesMenu,
	timeTrackEditMenu: TimeTrackEditMenu,
	changeRequestMenu: ChangeRequestMenu
};

const ActionSheet = () => {
	const dispatch = useDispatch();
	const menuToShow = useSelector((state) => state.auth.ui.actionSheetOpen);

	let MenuComponent = menuMap[menuToShow];
	console.log(menuMap[menuToShow]);
	const closeActionSheet = () => {
		dispatch(updateActionSheet(false));
	};

	if (menuToShow === ACTIONSHEET.PLACEHOLDER) return <LoadingAnimationMenu />;
	if (!MenuComponent) return null;

	return (
		<div className="action-sheet" onClick={closeActionSheet}>
			<div className="action-sheet-content">
				<MenuComponent closeActionSheet={closeActionSheet} />
				<div className="action-sheet-option close" onClick={closeActionSheet}>
					{t('common.closeButton')}
				</div>
			</div>
		</div>
	);
};

const LoadingAnimationMenu = ({closeActionSheet}) => {
	return (
		<div className="action-sheet" onClick={closeActionSheet}>
			<div className="action-sheet-content">
				<div className="action-sheet-option">
					<BeatLoader size={10} />
				</div>
				<div className="action-sheet-option close" onClick={closeActionSheet}>
					{t('common.closeButton')}
				</div>
			</div>
		</div>
	);
};
export default ActionSheet;
