import {lazy} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ROUTES} from '../constants/routes';
import App from '../App';
import RepeatBooking from '../components/BookingsOverview/RepeatBooking/RepeatBooking';
import NoCertificateForCheckin from '../components/NoCertificateForCheckin';
import Notifications from '../components/ProfilePage/Notifications/Notifications';
import AspenList from '../components/Booking/AspenBooking/AspenList';
import AspenBookingPage from '../components/Booking/AspenBooking';
import {useDispatch} from 'react-redux';
import {startBackgroundTasks} from '../functions/backgroundTasks';
import OrganizationSwitcher from '../components/OrganizationSwitcher';
import NotificationSettings from '../components/ProfilePage/Settings/AppSettings/NotificationSettings';
import TimeTrackerOverview from '../components/TimeTracker/TimeTrackerOverview';
import TimeTrackerSettings from '../components/TimeTracker/TimeTrackerSettings';

function AppRoutes() {
	function lazyRetry(componentImport) {
		return new Promise((resolve, reject) => {
			// check if the window has already been refreshed
			const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
			//try to import the component
			componentImport()
				.then((component) => {
					window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
					resolve(component);
				})
				.catch((error) => {
					if (!hasRefreshed) {
						// not been refreshed yet
						window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
						return window.location.reload(); // refresh the page
					}
					reject(error);
				});
		});
	}
	const LoginPage = lazy(() => lazyRetry(() => import('../components/LoginPage')));
	const Dashboard = lazy(() => lazyRetry(() => import('../components/Dashboard')));
	const Bookings = lazy(() => lazyRetry(() => import('../components/BookingsOverview')));
	const QRScanner = lazy(() => lazyRetry(() => import('../components/QRScanner')));
	const ProfilePage = lazy(() => lazyRetry(() => import('../components/ProfilePage')));
	const BookingDetails = lazy(() => lazyRetry(() => import('../components/BookingsOverview/BookingDetails')));
	const AspenRequestDetails = lazy(() => lazyRetry(() => import('../components/BookingsOverview/AspenRequestDetails')));
	const CovidStatusPage = lazy(() => lazyRetry(() => import('../components/ProfilePage/CovidStatus/CovidStatusPage')));
	const ReportSickPage = lazy(() => lazyRetry(() => import('../components/ProfilePage/ReportSickPage')));
	const BadgePage = lazy(() => lazyRetry(() => import('../components/ProfilePage/BadgePage')));
	const DigitalBadge = lazy(() => lazyRetry(() => import('../components/ProfilePage/DigitalBadge')));
	const Settings = lazy(() => lazyRetry(() => import('../components/ProfilePage/Settings')));
	const AppSettingsPage = lazy(() => lazyRetry(() => import('../components/ProfilePage/Settings/AppSettings')));
	const HelpSettingsPage = lazy(() => lazyRetry(() => import('../components/ProfilePage/Settings/HelpSettingsPage')));
	const LanguageSettingsPage = lazy(() => lazyRetry(() => import('../components/ProfilePage/Settings/AppSettings/LanguageSettingsPage')));
	const BookingSettingsPage = lazy(() =>
		lazyRetry(() => import('../components/ProfilePage/Settings/AppSettings/BookingSettings/BookingSettingsPage')),
	);
	const TimeTrackingSettings = lazy(() => lazyRetry(() => import('../components/ProfilePage/Settings/AppSettings/TimeTrackingSettings')));
	const CheckInQuestionnairePage = lazy(() => lazyRetry(() => import('../components/CheckInQuestionnairePage')));
	const ReportProblem = lazy(() => lazyRetry(() => import('../components/ProfilePage/ReportProblem')));
	const NewMeeting = lazy(() => lazyRetry(() => import('../components/VisitorManagement/NewMeeting')));
	const NewVisitor = lazy(() => lazyRetry(() => import('../components/VisitorManagement/NewMeeting/NewVisitor')));
	const ShowAllVisitorCard = lazy(() => lazyRetry(() => import('../components/VisitorManagement/ShowAllVisitor')));
	const OfficeInfoPage = lazy(() => lazyRetry(() => import('../components/Dashboard/OfficeInfo/OfficeInfoPage')));
	const ConfirmBooking = lazy(() => lazyRetry(() => import('../components/Booking/ConfirmBooking/ConfirmBooking')));
	const ColleagueList = lazy(() => lazyRetry(() => import('../components/ProfilePage/ColleagueList/ColleagueList')));
	const OfficeSwitcherPage = lazy(() => lazyRetry(() => import('../components/Dashboard/OfficeSwitcherPage/OfficeSwitcherPage')));
	const MapViewPage = lazy(() => lazyRetry(() => import('../components/MapView/MapViewPage')));
	const AreaListPage = lazy(() => lazyRetry(() => import('../components/AreaListPage/AreaListPage')));
	const StatisticsInfoPage = lazy(() => lazyRetry(() => import('../components/Dashboard/StatisticsInfo/StatisticsInfoPage')));
	const CheckAvailability = lazy(() => lazyRetry(() => import('../components/CheckAvailability/CheckAvailability')));
	const AllVisitors = lazy(() => lazyRetry(() => import('../components/VisitorManagement/NewMeeting/AllVisitors')));
	const MeetingSummary = lazy(() => lazyRetry(() => import('../components/VisitorManagement/MeetingSummary')));
	const UnscheduledVisitors = lazy(() => lazyRetry(() => import('../components/VisitorManagement/UnscheduledVisitors')));
	const FindColleague = lazy(() => lazyRetry(() => import('../components/FindColleague/FindColleague')));
	const VerifyOTPPage = lazy(() => lazyRetry(() => import('../components/VerifyOTP/VerifyOTP')));
	const ProfileCompletion = lazy(() => lazyRetry(() => import('../components/VerifyOTP/ProfileCompletion')));
	const OfficeSelection = lazy(() => lazyRetry(() => import('../components/VerifyOTP/OfficeSelection')));
	const BookingPage = lazy(() => lazyRetry(() => import('../components/Booking/BookingPage')));
	const EntityDetails = lazy(() => lazyRetry(() => import('../components/Booking/EntityDetails/EntityDetails')));
	const FavoritesBooking = lazy(() => lazyRetry(() => import('../components/Booking/Favorites/FavoritesBooking')));
	const FeaturesPage = lazy(() => lazyRetry(() => import('../components/FeaturesPage/FeaturesPage')));
	const ColleaguePage = lazy(() => lazyRetry(() => import('../components/FindColleague/ColleaguePage')));
	const StartTourPage = lazy(() => lazyRetry(() => import('../components/LoginPage/StartTour')));
	const FinishedTourPage = lazy(() => lazyRetry(() => import('../components/LoginPage/FinishedTour')));
	const ErrorTeamsLogin = lazy(() => lazyRetry(() => import('../components/ErrorPage/ErrorTeamsLogin')));
	const StatsAndBadges = lazy(() => lazyRetry(() => import('../components/ProfilePage/StatsAndBadges')));
	const Stats = lazy(() => lazyRetry(() => import('../components/ProfilePage/StatsAndBadges/Stats')));
	const Badges = lazy(() => lazyRetry(() => import('../components/ProfilePage/StatsAndBadges/Badges')));
	const UnlockNotification = lazy(() => lazyRetry(() => import('../components/ProfilePage/StatsAndBadges/UnlockNotification')));
	const DataPrivacy = lazy(() => lazyRetry(() => import('./../components/ProfilePage/Settings/AppSettings/DataPrivacy')));
	const EditBooking = lazy(() => lazyRetry(() => import('../components/Booking/EditBooking/EditBooking')));
	const Agenda = lazy(() => lazyRetry(() => import('../components/Agenda/Agenda')));
	const AgendaItem = lazy(() => lazyRetry(() => import('../components/Agenda/AgendaItem')));
	const EventSupport = lazy(() => lazyRetry(() => import('../components/Event/EventSupport')));
	const EventAgenda = lazy(() => lazyRetry(() => import('../components/Event/EventAgenda')));
	const EventIntro = lazy(() => lazyRetry(() => import('../components/Event/EventIntro')));
	const EventPage = lazy(() => lazyRetry(() => import('../components/Event/EventPage')));
	const CGIMeter = lazy(() => lazyRetry(() => import('../components/Event/CGIMeter')));
	const SessionDetails = lazy(() => lazyRetry(() => import('../components/Event/SessionDetails')));
	const MemberProfile = lazy(() => lazyRetry(() => import('../components/ProfilePage/MemberProfile/MemberProfile')));
	const MemberProfileEdit = lazy(() => lazyRetry(() => import('../components/ProfilePage/MemberProfile/MemberProfileEdit')));
	const EventAttendees = lazy(() => lazyRetry(() => import('../components/Event/EventAttendees/EventAttendees')));
	const BookableSessionPage = lazy(() => lazyRetry(() => import('../components/Event/BookableSessionPage')));
	const LeadershipBoard = lazy(() => lazyRetry(() => import('../components/Event/LeadershipBoard')));
	const OfficeElement = lazy(() => lazyRetry(() => import('../components/OfficeElement/OfficeElement')));
	const OwnAssets = lazy(() => lazyRetry(() => import('../components/ProfilePage/OwnAssets/OwnAssets')));
	const ShareAsset = lazy(() => lazyRetry(() => import('../components/ProfilePage/OwnAssets/ShareAsset')));

	const dispatch = useDispatch();
	startBackgroundTasks(dispatch);

	return (
		<Router>
			<Switch>
				<Route
					path={ROUTES.LOGIN}
					render={(props) => (
						<App {...props} pageContent={ROUTES.LOGIN}>
							<LoginPage />
						</App>
					)}
				/>
				<Route
					path={ROUTES.EVENT_SPLASH}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT_SPLASH}>
							<Dashboard pageContent={ROUTES.EVENT_SPLASH} />
						</App>
					)}
				/>
				<Route
					path={ROUTES.BOOKING}
					render={(props) => (
						<App {...props} pageContent={ROUTES.BOOKING}>
							<Dashboard pageContent={ROUTES.BOOKING} />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.START_TOUR}
					render={(props) => (
						<App {...props} pageContent={ROUTES.START_TOUR}>
							<StartTourPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.FINISHED_TOUR}
					render={(props) => (
						<App {...props} pageContent={ROUTES.FINISHED_TOUR}>
							<FinishedTourPage />
						</App>
					)}
				/>
				<Route
					exact
					path={'/organizationSwitcher'}
					render={(props) => (
						<App {...props} pageContent={'/organizationSwitcher'}>
							<OrganizationSwitcher />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.VERIFY_OTP}
					render={(props) => (
						<App {...props} pageContent={ROUTES.VERIFY_OTP}>
							<VerifyOTPPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.PROFILE_COMPLETION}
					render={(props) => (
						<App {...props} pageContent={ROUTES.PROFILE_COMPLETION}>
							<ProfileCompletion />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.OFFICE_SELECTION}
					render={(props) => (
						<App {...props} pageContent={ROUTES.OFFICE_SELECTION}>
							<OfficeSelection />
						</App>
					)}
				/>
				<Route exact path={ROUTES.TEAMS} render={() => <App pageContent={ROUTES.TEAMS} />} />
				<Route
					exact
					path={ROUTES.DEFAULT}
					render={(props) => (
						<App {...props} pageContent={ROUTES.DEFAULT}>
							<Dashboard />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.OFFICE_SWITCHER}
					render={(props) => (
						<App {...props} pageContent={ROUTES.OFFICE_SWITCHER}>
							<OfficeSwitcherPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.QUESTIONNER_PAGE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.DEFAULT}>
							<CheckInQuestionnairePage />
						</App>
					)}
				/>
				<Route
					path={ROUTES.SHORT_URL_REDIRECT}
					render={(props) => (
						<App {...props} pageContent={ROUTES.SHORT_URL_REDIRECT}>
							<Dashboard />
						</App>
					)}
				/>
				<Route
					path={ROUTES.BOOKING_PAGE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.BOOKING_PAGE}>
							<BookingPage />
						</App>
					)}
				/>
				<Route
					path={ROUTES.BOOKINGS_OVERVIEW}
					render={(props) => (
						<App {...props} pageContent={ROUTES.BOOKINGS_OVERVIEW}>
							<Bookings />
						</App>
					)}
				/>
				<Route
					path={ROUTES.STATISTICS_INFO_PAGE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.STATISTICS_INFO_PAGE}>
							<StatisticsInfoPage />
						</App>
					)}
				/>
				<Route
					path={ROUTES.OFFICE_INFO_PAGE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.OFFICE_INFO_PAGE}>
							<OfficeInfoPage />
						</App>
					)}
				/>
				<Route
					path={ROUTES.REPEAT_BOOKING}
					render={(props) => (
						<App {...props} pageContent={ROUTES.REPEAT_BOOKING}>
							<RepeatBooking />
						</App>
					)}
				/>
				<Route
					path={ROUTES.BOOKING_CONFIRM}
					render={(props) => (
						<App {...props} pageContent={ROUTES.REPEAT_BOOKING}>
							<ConfirmBooking />
						</App>
					)}
				/>
				<Route
					path={ROUTES.QR_SCANNER}
					render={(props) => (
						<App {...props} pageContent={ROUTES.QR_SCANNER}>
							<QRScanner />
						</App>
					)}
				/>
				<Route
					path={ROUTES.PROFILE_PAGE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.PROFILE_PAGE}>
							<Switch>
								<Route exact path={ROUTES.PROFILE_PAGE}>
									<ProfilePage />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.COVID_STATUS_PAGE}>
									<CovidStatusPage />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.REPORT_SICK_PAGE}>
									<ReportSickPage />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.BADGE}>
									<BadgePage />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.OWN_ASSETS}>
									<OwnAssets />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.SHARE_ASSET}>
									<ShareAsset />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.DIGITAL_BADGE}>
									<DigitalBadge />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.AGENDA}>
									<Agenda />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.ADD_AGENDA_ITEM}>
									<AgendaItem />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.STATS_AND_BADGES}>
									<StatsAndBadges />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.STATS}>
									<Stats />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.BADGES}>
									<Badges />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.UNLOCK_NOTIFICATION}>
									<UnlockNotification />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.NOTIFICATIONS}>
									<Notifications />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.COLLEAGUE_LIST}>
									<ColleagueList />
								</Route>
								<Route exact path={ROUTES.PROFILE_PAGE + ROUTES.SETTINGS}>
									<Settings />
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE}>
									<Switch>
										<Route exact path={ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE}>
											<AppSettingsPage />
										</Route>
										<Route path={ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE + ROUTES.LANGUAGE_SETTINGS_PAGE}>
											<LanguageSettingsPage />
										</Route>
										<Route exact path={ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE + ROUTES.BOOKING_SETTINGS_PAGE}>
											<BookingSettingsPage />
										</Route>
										<Route exact path={ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE + ROUTES.TIME_TRACKING_SETTINGS_PAGE}>
											<TimeTrackingSettings />
										</Route>
										<Route exact path={ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE + ROUTES.NOTIFICATION_SETTINGS_PAGE}>
											<NotificationSettings />
										</Route>
										<Route exact path={ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE + ROUTES.DATA_PRIVACY_SETTINGS_PAGE}>
											<DataPrivacy />
										</Route>
									</Switch>
								</Route>
								<Route path={ROUTES.PROFILE_PAGE + ROUTES.HELP_SETTINGS_PAGE}>
									<Switch>
										<Route exact path={ROUTES.PROFILE_PAGE + ROUTES.HELP_SETTINGS_PAGE}>
											<HelpSettingsPage />
										</Route>
									</Switch>
								</Route>
							</Switch>
						</App>
					)}
				/>
				<Route
					path={ROUTES.REPORT_PROBLEM}
					render={(props) => (
						<App {...props} pageContent={ROUTES.REPORT_PROBLEM}>
							<ReportProblem />
						</App>
					)}
				/>
				<Route
					path={ROUTES.MEMBER_BADGE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.MEMBER_BADGE}>
							<Dashboard redirectToMemberBadgeModal={true} />
						</App>
					)}
				/>
				<Route
					path={ROUTES.USER_REVIEW}
					render={(props) => (
						<App {...props} pageContent={ROUTES.USER_REVIEW}>
							<Dashboard redirectToUserReview={true} />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.NO_CERTIFICATE_FOR_CHECKIN}
					render={(props) => (
						<App {...props} pageContent={ROUTES.DEFAULT}>
							<NoCertificateForCheckin />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.FAVORITES_BOOKING_PAGE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.FAVORITES_BOOKING_PAGE}>
							<FavoritesBooking />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.FEATURES_LIST}
					render={(props) => (
						<App {...props} pageContent={ROUTES.FEATURES_LIST}>
							<FeaturesPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.VISITOR.NEW_MEETING}
					render={(props) => (
						<App {...props} pageContent={ROUTES.VISITOR.NEW_MEETING}>
							<NewMeeting />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.VISITOR.EDIT_VISITOR}
					render={(props) => (
						<App {...props} pageContent={ROUTES.VISITOR.NEW_VISITOR}>
							<NewVisitor />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.VISITOR.NEW_VISITOR}
					render={(props) => (
						<App {...props} pageContent={ROUTES.VISITOR.NEW_VISITOR}>
							<NewVisitor />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.VISITOR.MEETING_SUMMARY}
					render={(props) => (
						<App {...props} pageContent={ROUTES.VISITOR.MEETING_SUMMARY}>
							<MeetingSummary />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.VISITOR.UNSCHEDULED_VISITORS}
					render={(props) => (
						<App {...props} pageContent={ROUTES.VISITOR.UNSCHEDULED_VISITORS}>
							<UnscheduledVisitors />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.VISITOR.ALL_VISITORS}
					render={(props) => (
						<App {...props} pageContent={ROUTES.VISITOR.ALL_VISITORS}>
							<AllVisitors />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.VISITOR.SHOW_ALL}
					render={(props) => (
						<App {...props} pageContent={ROUTES.VISITOR.SHOW_ALL}>
							<ShowAllVisitorCard />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.SEE_DETAILS_OF_BOOKING}
					render={(props) => (
						<App {...props} pageContent={ROUTES.SEE_DETAILS_OF_BOOKING}>
							<BookingDetails />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.CHECK_AVAILABILITY}
					render={(props) => (
						<App {...props} pageContent={ROUTES.CHECK_AVAILABILITY}>
							<CheckAvailability />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.AREA_LIST}
					render={(props) => (
						<App {...props} pageContent={ROUTES.AREA_LIST}>
							<AreaListPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.MAP_VIEW}
					render={(props) => (
						<App {...props} pageContent={ROUTES.MAP_VIEW}>
							<MapViewPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.FIND_COLLEAGUE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.FIND_COLLEAGUE}>
							<FindColleague />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.COLLEAGUE_PAGE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.COLLEAGUE_PAGE}>
							<ColleaguePage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EDIT_BOOKING}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EDIT_BOOKING}>
							<EditBooking />
						</App>
					)}
				/>
				<Route
					exact
					path={'/successPaymentForBooking'}
					render={(props) => (
						<App {...props} pageContent={ROUTES.DEFAULT}>
							<Dashboard />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.ASPEN_BOOKING}
					render={(props) => (
						<App {...props} pageContent={ROUTES.ASPEN_BOOKING}>
							<AspenBookingPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.ASPEN_LIST}
					render={(props) => (
						<App {...props} pageContent={ROUTES.ASPEN_LIST}>
							<AspenList />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.ASPEN_DETAILS}
					render={(props) => (
						<App {...props} pageContent={ROUTES.ASPEN_DETAILS}>
							<AspenRequestDetails />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EVENT_INTRO}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT_INTRO}>
							<EventIntro />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EVENT}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT}>
							<EventPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.SESSION_DETAILS}
					render={(props) => (
						<App {...props} pageContent={ROUTES.SESSION_DETAILS}>
							<SessionDetails />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.MEMBER_PROFILE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.MEMBER_PROFILE}>
							<MemberProfile />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EDIT_MEMBER_PROFILE}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EDIT_MEMBER_PROFILE}>
							<MemberProfileEdit />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EVENT_AGENDA}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT_AGENDA}>
							<EventAgenda />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EVENT_SUPPORT}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT_SUPPORT}>
							<EventSupport />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.CGIMETER}
					render={(props) => (
						<App {...props} pageContent={ROUTES.CGIMETER}>
							<CGIMeter />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.EVENT_ATTENDEES}
					render={(props) => (
						<App {...props} pageContent={ROUTES.EVENT_ATTENDEES}>
							<EventAttendees />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.BOOKABLE_SESSION}
					render={(props) => (
						<App {...props} pageContent={ROUTES.BOOKABLE_SESSION}>
							<BookableSessionPage />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.OFFICE_ELEMENT}
					render={(props) => (
						<App {...props} pageContent={ROUTES.OFFICE_ELEMENT}>
							<OfficeElement />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.LEADERSHIP_BOARD}
					render={(props) => (
						<App {...props} pageContent={ROUTES.LEADERSHIP_BOARD}>
							<LeadershipBoard />
						</App>
					)}
				/>
				<Route
					exact
					path={ROUTES.ENTITY_DETAILS}
					render={(props) => (
						<App {...props} pageContent={ROUTES.ENTITY_DETAILS}>
							<EntityDetails />
						</App>
					)}
				/>
				<Route
					path={ROUTES.TIME_TRACKER.MAIN}
					render={(props) => (
						<App {...props} pageContent={ROUTES.TIME_TRACKER.MAIN}>
							<Switch>
								<Route exact path={ROUTES.TIME_TRACKER.MAIN}>
									<TimeTrackerOverview />
								</Route>
								<Route exact path={ROUTES.TIME_TRACKER.MAIN + ROUTES.TIME_TRACKER.ACTIVITY_EDIT}>
									<TimeTrackerSettings editActivity={true} />
								</Route>
								<Route exact path={ROUTES.TIME_TRACKER.MAIN + ROUTES.TIME_TRACKER.ACTIVITY_NEW}>
									<TimeTrackerSettings newActivity={true} />
								</Route>
							</Switch>
						</App>
					)}
				/>
				<Route exact path={'/errorPayment'} render={(props) => <div>error payment</div>} />
				<Route exact path={ROUTES.ERROR_TEAMS_LOGIN} render={(props) => <ErrorTeamsLogin />} />
			</Switch>
		</Router>
	);
}

export default AppRoutes;
