import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { Typography } from '../Common/Typography';

const TimeTrackEditMenu = () => {
	const dispatch = useDispatch();
	const authData = useSelector((state) => state.auth.data);
	const { actionSheetData } = authData;
	const history = useHistory();

	const handleEditButton = () => {
		history.push(ROUTES.TIME_TRACKER.MAIN + ROUTES.TIME_TRACKER.ACTIVITY_EDIT, { actionSheetData });
	};

	return (
		<div className="action-sheet-option" onClick={handleEditButton}>
			{t('timeTracker.buttons.edit')}
		</div>
	);
};

export default TimeTrackEditMenu;
