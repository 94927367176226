import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import Icon from '../Common/Icon';
import { updateActionSheet, updateActionSheetData } from '../../store/auth';
import { ACTIONSHEET } from '../../constants/actionSheets';
import FormattedTime from '../Common/DateTimePicker/FormattedTime';
import Button from '../Common/Button';
import { DateTime, Duration } from 'luxon';
import { stopTimeRecord } from '../../api/timeTracker';

const WORK_TYPE_IMAGES = {
	work: 'work',
	pause: 'pause',
	sickLeave: 'thermometer',
	vacation: 'plane',
}
const TIME_SECOND = 1000;
let timerInterval;
/**
 * @param timeRecord is an element of an array which includes a time record
 */
const TimeTrackerOverviewItem = ({ timeRecord }) => {
	const dispatch = useDispatch();
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const selectedOfficeTimezone = selectedOffice.timezone;
	const selectedOfficeId = selectedOffice._id;
	const [timerOngoing, setTimerOngoing] = useState(timeRecord?.end?.time === undefined);
	const [duration, setDuration] = useState(0);

	useEffect(() => {
		if (timerOngoing) {
			startTimer();
		}
		return () => {
			clearInterval(timerInterval);
		}
	}, []);
	function calculateWorkDuration(record) {
		let totalDuration = Duration.fromObject({ hours: 0 });

		const start = DateTime.fromISO(record.start.time);
		const end = DateTime.now();
		const duration = end.diff(start);
		totalDuration = totalDuration.plus(duration);

		return totalDuration;
	}
	function startTimer() {
		timerInterval = setInterval(() => {
			const newWorkDuration = calculateWorkDuration(timeRecord);
			setDuration(newWorkDuration);
		}, TIME_SECOND);
	}
	const handleStopButton = () => {
		stopTimeRecord({ office: selectedOfficeId }).then(() => {
			setTimerOngoing(false);
			clearInterval(timerInterval);
		}).catch(err => console.log("An error occured while pressing stop:", err));
	};
	const openActionSheet = (e) => {
		e.stopPropagation();
		dispatch(updateActionSheet(ACTIONSHEET.TIME_TRACK_EDIT_MENU));
		dispatch(updateActionSheetData(timeRecord));
	};

	return (
		<div className='time-tracks-item'	>
			<div className="time-tracks-item-type">
				{timerOngoing ? (
					<>
						<div className="header">
							<Icon iconName={WORK_TYPE_IMAGES[timeRecord?.name]} cssClass="icon-black" />
							<div className="work-type-container">
								<p className="work-type">{t('timeTracker.options.' + timeRecord?.name)}</p>
								<p className="from-to-time">
									<span>{t('common.dateTimePicker.from')}:</span>
									<FormattedTime time={timeRecord?.start?.time} timezone={selectedOfficeTimezone} />
								</p>
								<p className="ongoing-time">
									{Duration.fromMillis(duration)?.toFormat('hh:mm:ss')}
								</p>
							</div>
						</div>
						<>
							<Icon direct={true} iconName="fa-regular fa-circle-stop" cssClass="icon-red" clickHandler={handleStopButton} />
						</>
						{/* <div>

							<Button
								variant={'tertiary'}
								height={'regular'}
								noWidth={true}
								clickHandler={handleStopButton}
								iconName={'fa-regular fa-circle-stop'}
								cssClass={'resumeTimer'}
							/>
						</div> */}
					</>
				) : (
					<>
						<div className="header">
							<Icon iconName={WORK_TYPE_IMAGES[timeRecord?.name]} cssClass="icon-black" />
							<div className="work-type-container">
							<p className="work-type">{t('timeTracker.options.' + timeRecord?.name)}</p>
								<p className="from-to-time">
									<span>{t('common.dateTimePicker.from')}:</span>
									<FormattedTime time={timeRecord?.start?.time} timezone={selectedOfficeTimezone} />
								</p>
								<p className="from-to-time">
									<span>{t('common.dateTimePicker.to')}:</span>
									{timerOngoing && <FormattedTime time={DateTime.fromISO(timeRecord?.start?.time).plus({ millisecond: duration })} timezone={selectedOfficeTimezone} />}
									{!timerOngoing && (<FormattedTime time={timeRecord?.end?.time || DateTime.now()} timezone={selectedOfficeTimezone} />)}

								</p>
							</div>
						</div>
						<>
							<Icon iconName="ellipsis" cssClass="icon-grey" clickHandler={openActionSheet} />
						</>
					</>
				)
				}

			</div>
		</div>
	);
};

export default TimeTrackerOverviewItem;
