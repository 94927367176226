import axios from 'axios';
import apiUrl from './index';

/**
 * returns all records of the user in the specified time range
 * @param startTime the start date without setted zone
 * @param endTime the end date without setted zone
 * @param office the current office in which we get the TimeRecords
 */
export async function findInRange({ office, startTime, endTime }) {
    const satelliteUrl = window.localStorage.getItem('satelliteUrl');
    const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
    let requestUrl = hostUrl + apiUrl.path.cedarRecord + apiUrl.path.findInRangeTimeRecord;

    return axios({
        method: 'post',
        url: requestUrl,
        data: {
            office,
            start: startTime,
            end: endTime
        }
    });
}

/**
 * @param startTime defines the start date from where we start to find time records.
 * @param endTime same as startTime but defines the upper boundary.
 * @returns array with objects that represents time tracking records.
 */
export const getRangeOfTimeRecords = async (office, startTime, endTime) => {
	return findInRange({ office, startTime, endTime });
}

/**
 * creates a new record with the start = now. If there is an ongoing session, it is stopped, before the new one is created
 * @param office the current office in which we get the TimeRecords
 * @param name the name of current TimeRecord
 * @param notes the note that user wrote to the respective TimeRecord
 */
export async function startTimeRecord({ name, notes, office }) {
    const satelliteUrl = window.localStorage.getItem('satelliteUrl');
    const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
    let requestUrl = hostUrl + apiUrl.path.cedarRecord + apiUrl.path.startTimeRecord;

    return axios({
        method: 'post',
        url: requestUrl,
        data: {
            name,
            notes,
            office
        }
    });
}
/**
 * deletes a certain record depending on ID and office
 * @param timeRecordID the start date without setted zone
 * @param office the current office in which we get the TimeRecords
 */
export async function deleteTimeRecord({ timeRecordID, office }) {
    const satelliteUrl = window.localStorage.getItem('satelliteUrl');
    const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
    let requestUrl = hostUrl + apiUrl.path.cedarRecord + '/' + String(timeRecordID) + apiUrl.path.deleteTimeRecord;

    return axios({
        method: 'delete',
        url: requestUrl,
        data: {
            office
        }
    });
}
/**
 * updates the record, cannot edit future ones 
 * @param timeRecordID the start date without setted zone
 * @param startTime the start date without setted zone
 * @param endTime the end date without setted zone
 * @param office the current office in which we get the TimeRecords
 * @param name the name of current TimeRecord,
 * @param notes the note that user wrote to the respective TimeRecord
 */
export async function updateTimeRecord({ timeRecordID, office, name, startTime, endTime, notes }) {
    const satelliteUrl = window.localStorage.getItem('satelliteUrl');
    const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
    let requestUrl = hostUrl + apiUrl.path.cedarRecord + '/' + String(timeRecordID) + apiUrl.path.updateTimeRecord;

    return axios({
        method: 'put',
        url: requestUrl,
        data: {
            office,
            name,
            notes,
            start: startTime,
            end: endTime
        }
    });
}
/**
 * it ends the last TimeRecord
 * @param office the current office in which we get the TimeRecords
 */
export async function stopTimeRecord({ office }) {
    const satelliteUrl = window.localStorage.getItem('satelliteUrl');
    const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
    let requestUrl = hostUrl + apiUrl.path.cedarRecord + apiUrl.path.stopTimeRecord;

    return axios({
        method: 'post',
        url: requestUrl,
        data: {
            office
        }
    });
}
/**
 * Adds a new TimeRecord depending the start and end time
 * @param start the start date without setted zone
 * @param end the end date without setted zone
 * @param office the current office in which we get the TimeRecords
 * @param name the name of current TimeRecord,
 * @param notes the note that user wrote to the respective TimeRecord
 */
export async function addTimeRecord({ name, notes, office, start, end }) {
    const satelliteUrl = window.localStorage.getItem('satelliteUrl');
    const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
    let requestUrl = hostUrl + apiUrl.path.cedarRecord + apiUrl.path.addTimeRecord;

    return axios({
        method: 'post',
        url: requestUrl,
        data: {
            office,
            name,
            notes,
            start,
            end
        }
    });
}

/**
 * Gets the cedar options for a specific office
 * @param office
 * @returns {AxiosPromise<any>}
 */
export async function getCedarOptions(office) {
    const satelliteUrl = window.localStorage.getItem('satelliteUrl');
    const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
    let requestUrl = hostUrl + apiUrl.path.cedar + apiUrl.path.getCedarOptions;

    return axios({
        method: 'post',
        url: requestUrl,
        data: {
            office
        }
    });
}

/**
 * Gets the cedar change requests for a specific office and date
 * @param office
 * @param startTime
 * @param endTime
 * @returns Array of change requests
 */
export async function getCedarChangeRequests(office) {
    const satelliteUrl = window.localStorage.getItem('satelliteUrl');
    const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
    let requestUrl = hostUrl + apiUrl.path.cedar + apiUrl.path.getCedarChangeRequests;

    return axios({
        method: 'post',
        url: requestUrl,
        data: {
            office,
        }
    });
}

export async function deleteCedarChangeRequest(cedarChangeRequest) {
    const satelliteUrl = window.localStorage.getItem('satelliteUrl');
    const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
    let requestUrl = hostUrl + apiUrl.path.cedar + apiUrl.path.changeRequest + '/' + cedarChangeRequest;

    return axios({
        method: 'delete',
        url: requestUrl,
        data: {
        }
    });
}

export async function setChangeRequestHidden(cedarChangeRequest) {
    const satelliteUrl = window.localStorage.getItem('satelliteUrl');
    const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
    let requestUrl = hostUrl + apiUrl.path.cedar + apiUrl.path.changeRequest + '/' + cedarChangeRequest;

    return axios({
        method: 'put',
        url: requestUrl,
        data: {
            hidden: true
        }
    });
}