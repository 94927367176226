import {useSelector} from 'react-redux';
import {DateTime} from 'luxon';

/**
 * Component that retrieves a formatted date string used in the app. It changes day names based on user language
 *
 * @param {iso} date = date in iso format
 * @param {string} timezone = timezone of the shown date time string
 * @returns
 */
function FormattedDate({date, timezone}) {
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const dateFormat = useSelector((state) => state.auth.data.dateFormat);
	const userData = useSelector((state) => state.auth.data.userData);
	const formattedDate = `
    ${DateTime.fromISO(date)
		.setZone(timezone ? timezone : selectedOffice?.timezone)
		.setLocale(userData?.language)
		.toFormat(`ccc ${dateFormat}`)}`;
	return formattedDate;
}

export default FormattedDate;
