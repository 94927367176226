import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {enableCard} from '../../../store/cards';

import Icon from '../../Common/Icon';

function MemberCard() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const member = useSelector((state) => state.cards.data);

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	return (
		<div className="member-badge-modal show">
			<div className="modal-member-card">
				<div className="close-badge-modal">
					<span className="close" onClick={closeCard}>
						&times;
					</span>
				</div>
				<div className="member-card">
					<div className="member-card-profile-photo">
						<img src={member?.photoURL} />
					</div>
					<div className="member-card-name">{`${member?.name?.first} ${member?.name?.last}`}</div>
					<div className="member-card-job-title">{member?.jobTitle}</div>
					<div className="member-card-identity-verified">
						<Icon cssClass={'icon-green'} iconName={'id-badge'} />
						<p>{t('badgeCard.badgeModal.identityVerified')}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MemberCard;
