import get from 'lodash/get';
const featureTranslationLanguages = ['en', 'de', 'fr', 'ru', 'nl', 'it', 'pt'];

function getFeatureTranslation(featureObj, featureTranslations, selectedLanguage) {
	let language = featureTranslationLanguages?.includes(selectedLanguage?.toLowerCase()) ? selectedLanguage : 'en';
	if (language === 'ru' || language === 'RU') language = 'fr'; // we use ru language code for canadian french
	let featureNameTranslation = featureObj?.translations?.filter((translation) => translation.language === language?.toLowerCase())[0]?.name;
	if(!featureNameTranslation) {
		featureNameTranslation = get(featureTranslations[featureObj?.name]?.translation, language);
	}
	if (!featureNameTranslation) {
		featureNameTranslation = featureObj?.translations?.filter((translation) => translation.language === 'en')[0]?.name;
	}
	return featureNameTranslation;
}

export {getFeatureTranslation};
