import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {DateTime} from 'luxon';

import {ROUTES} from '../../constants/routes';
import Icon from '../Common/Icon';

function BottomMenu({page}) {
	const history = useHistory();
	const {t} = useTranslation();
	const showCard = useSelector((state) => state.cards.showCard);
	const validCeylon = useSelector((state) => state.dashboard.data.validCeylon);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const lastCompleted = useSelector((state) => state.dashboard.data.plusStatusLastCompleted);
	const nbOfUnreadNotifications = useSelector((state) => state.profile.notifications.nbOfUnreadNotifications);
	const disableDashboard = useSelector((state) => state.auth.ui.disableDashboard);
	const [selectedOption, setSelectedOption] = useState(1);
	const selectedEvent = useSelector((state) => state.events.selectedEvent);
	let exclamationIcon = null;

	if (selectedOffice.ceylon?.enabled && selectedOffice.ceylon?.rule !== 'none') {
		if (validCeylon) {
			exclamationIcon = null;
		} else {
			exclamationIcon = <Icon iconName="exclamation-circle" size={'1x'} cssClass={'icon-invalid-certificate icon-red'} />;
		}
	}

	if (selectedOffice.ceylon?.rule === '2G+') {
		if (!lastCompleted || DateTime.fromISO(lastCompleted).plus({hours: 24}).toUTC() < DateTime.now().toUTC()) {
			exclamationIcon = <Icon iconName="exclamation-circle" size={'1x'} cssClass={'icon-invalid-certificate icon-red'} />;
		}
	}

	useEffect(() => {
		let newSelectedOption = 1;
		switch (page) {
			case ROUTES.BOOKINGS_OVERVIEW:
				newSelectedOption = 2;
				break;
			case ROUTES.REPORT_PROBLEM:
				newSelectedOption = 3;
				break;
			case ROUTES.PROFILE_PAGE:
				newSelectedOption = 4;
				break;
			case ROUTES.EVENT_SUPPORT:
				newSelectedOption = 3;
				break;
			default:
				newSelectedOption = 1;
				break;
		}

		if (newSelectedOption !== selectedOption) setSelectedOption(newSelectedOption);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const firstOptionClickHandler = () => {
		history.push(selectedEvent ? ROUTES.EVENT : ROUTES.DEFAULT);
	};

	const secondOptionClickHandler = () => {
		history.push(selectedEvent ? ROUTES.EVENT_AGENDA : ROUTES.BOOKINGS_OVERVIEW);
	};

	const thirdOptionClickHandler = () => {
		history.push(selectedEvent ? ROUTES.EVENT_SUPPORT : ROUTES.REPORT_PROBLEM);
	};

	const fourthOptionClickHandler = () => {
		history.push(ROUTES.PROFILE_PAGE);
	};

	const qrCodeOptionClickHandler = () => {
		history.push(ROUTES.QR_SCANNER);
	};

	let pageCss = showCard ? 'page-bottom-menu blurred' : 'page-bottom-menu ';

	return (
		<div className={pageCss}>
			<div className="options-wrapper">
				<div className="option home-option" onClick={firstOptionClickHandler}>
					<Icon iconName="home" size={'lg'} cssClass={selectedOption === 1 ? 'icon-active' : 'icon-disabled'} />
					<p className={selectedOption === 1 ? 'active' : 'disabled'}>{t('dashboard.homeOption')}</p>
					<div className={'purple-bar ' + (selectedOption === 1 ? 'active-bar' : '')}></div>
				</div>
				<div className="option bookings-option" onClick={secondOptionClickHandler}>
					<Icon iconName={'clock'} size={'lg'} type={'far'} cssClass={selectedOption === 2 ? 'icon-active' : 'icon-disabled'} />
					<p className={selectedOption === 2 ? 'active' : 'disabled'}>{t(selectedEvent ? 'common.agenda' : 'dashboard.bookingsOption')}</p>
					<div className={'purple-bar ' + (selectedOption === 2 ? 'active-bar' : '')}></div>
				</div>
			</div>
			<div className={disableDashboard ? 'option qr-code-option disabled' : 'option qr-code-option'} onClick={qrCodeOptionClickHandler}>
				<Icon iconName="qrcode" size={'lg'} cssClass={'icon-white'} />
			</div>
			<div className="options-wrapper">
				<div className="option reportProblem-option" onClick={thirdOptionClickHandler}>
					<Icon
						iconName={selectedEvent ? 'question' : 'fa-triangle-exclamation'}
						size={'lg'}
						cssClass={selectedOption === 3 ? 'icon-active' : 'icon-disabled'}
					/>
					<p className={selectedOption === 3 ? 'active' : 'disabled'}>
						{t(selectedEvent ? 'common.support' : 'dashboard.reportProblemOption')}
					</p>
					<div className={'purple-bar ' + (selectedOption === 3 ? 'active-bar' : '')}></div>
				</div>
				<div className="option profile-option" onClick={fourthOptionClickHandler}>
					<Icon iconName="user" size={'lg'} type={'far'} cssClass={selectedOption === 4 ? 'icon-active' : 'icon-disabled'} />
					{exclamationIcon}
					{nbOfUnreadNotifications > 0 && !exclamationIcon ? (
						<div className={`new-notifications ${nbOfUnreadNotifications > 99 ? 'new-notifications-minimum-size' : ''}`}>
							{nbOfUnreadNotifications}
						</div>
					) : null}
					<p className={selectedOption === 4 ? 'active' : 'disabled'}>{t('profile.headers.mainHeader')}</p>
					<div className={'purple-bar ' + (selectedOption === 4 ? 'active-bar' : '')}></div>
				</div>
			</div>
		</div>
	);
}

export default BottomMenu;
