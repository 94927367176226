import axios from 'axios';
import apiUrl from './index';

const pathUrls = {
	teakBooking: '/biro/teak/booking',
	checkInNow: '/checkInNow',
	checkOutNow: '/checkOutNow',
	extend: '/extend',
	series: '/series',
	cancelAttendance: '/cancelAttendance',
	edit: '/edit',
	delete: '/anonymize',
	deleteSeries: '/anonymizeSeries',
};

export async function findBookingsInRange(start, end, filters, skips, pageSize, sort, teakTypeIds) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakBooking + apiUrl.path.findInRange;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			start,
			end,
			filters,
			skips,
			pageSize,
			sort,
			teakTypeIds,
		},
	});
}

export async function checkInNow(teakBookingId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakBooking + '/' + teakBookingId + pathUrls.checkInNow;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			checkInType: 'checkedIn',
		},
	});
}

export async function checkOutNow(teakBookingId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakBooking + '/' + teakBookingId + pathUrls.checkOutNow;

	return axios({
		method: 'post',
		url: requestUrl,
	});
}

export async function extendTeakBooking(teakBookingId, until) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakBooking + '/' + teakBookingId + pathUrls.extend;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			until,
		},
	});
}

export async function editTeakBooking(teakBookingId, start, end, meetingName, attendees, teakEntity) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakBooking + '/' + teakBookingId + pathUrls.edit;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			start,
			end,
			meetingName,
			attendees,
			teakEntity,
		},
	});
}

export async function deleteAllRecurringBookings(teakBookingId, seriesId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakBooking + '/' + teakBookingId + pathUrls.deleteSeries + '/' + seriesId;

	return axios({
		method: 'put',
		url: requestUrl,
	});
}
export async function deleteBooking(teakBookingId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakBooking + '/' + teakBookingId + pathUrls.delete;

	return axios({
		method: 'put',
		url: requestUrl,
	});
}

export async function cancelBooking(teakBookingId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakBooking + '/' + teakBookingId;

	return axios({
		method: 'put',
		url: requestUrl,
	});
}

export async function cancelBookingSeries(seriesId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakBooking + pathUrls.series + '/' + seriesId;

	return axios({
		method: 'put',
		url: requestUrl,
	});
}

export async function cancelAttendance(teakBookingId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakBooking + '/' + teakBookingId + pathUrls.cancelAttendance;

	return axios({
		method: 'delete',
		url: requestUrl,
	});
}

/**
 *
 * @param {ID} teakBookingId = teak booking id
 * @returns Get booking data and entity data for specific id
 */
export async function getBooking(teakBookingId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.teakBooking + '/' + teakBookingId;

	return axios({
		method: 'get',
		url: requestUrl,
	});
}
