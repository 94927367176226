import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { updateNotificationSettings } from '../../../../../api/member';
import { CARD } from '../../../../../constants/cards';
import { enableBottomMenu, reloadUserData } from '../../../../../store/auth';
import { enableCard, updateErrorCard } from '../../../../../store/cards';
import SecondaryHeader from '../../../../Common/Header/SecondaryHeader';
import Switcher from '../../../../Common/Switcher';
import { Typography } from '../../../../Common/Typography';
import colorBasedOnTheme from './../../../../../functions/colorBasedOnTheme';

export default function NotificationSettings() {
	const { t } = useTranslation();
	const color = colorBasedOnTheme();
	const dispatch = useDispatch();
	const history = useHistory();
	const [dataLoading, setDataLoading] = useState(false);
	const userData = useSelector((state) => state.auth.data.userData);
	const notificationSettings = userData?.communications?.notifications?.emails;
	const {
		attendeeCancelled,
		bookOnBehalf,
		bookingCancelled,
		bookingConfirmed,
		bookingEdited,
		fillHealthQuestionnaire,
		lateCheckin,
		roomBookingInvite,
	} = notificationSettings ?? {};

	const backButtonHandler = () => {
		history.goBack();
	};

	useEffect(() => {
		dispatch(enableBottomMenu(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function handleToggle(key) {
		setDataLoading(true);
		try {
			await updateNotificationSettings(userData?._id, { ...notificationSettings, [key]: !notificationSettings[key] });
			dispatch(
				reloadUserData(() => {
					setDataLoading(false);
				}),
			);
		} catch (error) {
			dispatch(enableCard(true));
			dispatch(updateErrorCard(CARD.MAIN_ERROR));
			setDataLoading(false);
		}
	}
	return (
		<div className="notification-settings-page">
			<SecondaryHeader headerTranslationKey={'settings.notificationSettingHeader'} backButtonClickHandler={backButtonHandler} />
			{dataLoading ? (
				<div className="content-loading">
					<ClipLoader size={70} color={color.colorMainTheme} />
				</div>
			) : (
				<div className="notification-settings-page-content">
					<Typography variant="title-large">{t('bookingPage.assetBooking')}</Typography>
					<Typography variant="body-small">{t('settings.notificationSettings.infoHeader')}</Typography>
					<SelectSetting
						translation={'settings.notificationSettings.bookingConfirmed'}
						active={bookingConfirmed}
						handleToggle={() => handleToggle('bookingConfirmed')}
						id={'bookingConfirmed'}
					/>
					<SelectSetting
						translation={'settings.notificationSettings.bookOnBehalf'}
						active={bookOnBehalf}
						handleToggle={() => handleToggle('bookOnBehalf')}
						id={'bookOnBehalf'}
					/>
					<SelectSetting
						translation={'settings.notificationSettings.roomBookingInvite'}
						active={roomBookingInvite}
						handleToggle={() => handleToggle('roomBookingInvite')}
						id={'roomBookingInvite'}
					/>
					<SelectSetting
						translation={'settings.notificationSettings.attendeeCancelled'}
						active={attendeeCancelled}
						handleToggle={() => handleToggle('attendeeCancelled')}
						id={'attendeeCancelled'}
					/>
					<SelectSetting
						translation={'settings.notificationSettings.lateCheckin'}
						active={lateCheckin}
						handleToggle={() => handleToggle('lateCheckin')}
						id={'lateCheckin'}
					/>
					<SelectSetting
						translation={'settings.notificationSettings.bookingCancelled'}
						active={bookingCancelled}
						handleToggle={() => handleToggle('bookingCancelled')}
						id={'bookingCancelled'}
					/>
					<SelectSetting
						translation={'settings.notificationSettings.bookingEdited'}
						active={bookingEdited}
						handleToggle={() => handleToggle('bookingEdited')}
						id={'bookingEdited'}
					/>
					<SelectSetting
						translation={'settings.notificationSettings.fillHealthQuestionnaire'}
						active={fillHealthQuestionnaire}
						handleToggle={() => handleToggle('fillHealthQuestionnaire')}
						id={'fillHealthQuestionnaire'}
					/>
				</div>
			)}
		</div>
	);
}

function SelectSetting({ active, translation, handleToggle, id }) {
	const { t } = useTranslation();
	const color = colorBasedOnTheme();
	return (
		<div className="select-setting">
			<Typography variant="label-large">{t(translation)}</Typography>
			<Switcher id={id} handleToggle={handleToggle} isOn={active} onColor={color.colorMainTheme}></Switcher>
		</div>
	);
}
