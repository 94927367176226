import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {useHistory} from 'react-router-dom';

import {ROUTES} from '../../../constants/routes';
import {removeAttendeeFromList} from '../../../store/bookingProcess/entitiesBooking';
import {updateCheckedList} from '../../../store/settings';

import ColleagueItem from '../../ProfilePage/ColleagueList/ColleagueItem';
import Icon from '../../Common/Icon';
import {getColleaguesStatus} from '../../../api/member';
import AddPeopleSection from '../../Common/AddPeopleSection';

function AddPeople({showHideMore, attendeeList, showAddColleagueLabel = false, removeItemHandler, updateAttendeeListForBooking, hasMSOutlook}) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const attendeeListFromStore = useSelector((state) => state.entitiesBooking.attendeeList);
	const selectedStartTime = useSelector((state) => state.entitiesBooking.selectedStartTime);
	const selectedEndTime = useSelector((state) => state.entitiesBooking.selectedEndTime);
	attendeeList = attendeeList || attendeeListFromStore;
	const [showMore, setShowMore] = useState(false);
	const [colleaguesStatus, setColleaguesStatus] = useState();

	const goToColleagueList = () => {
		dispatch(updateCheckedList(attendeeList));
		if (updateAttendeeListForBooking) {
			history.push(ROUTES.PROFILE_PAGE + ROUTES.COLLEAGUE_LIST, {
				updateAttendeeListForBooking: true,
			});
		} else {
			history.push(ROUTES.PROFILE_PAGE + ROUTES.COLLEAGUE_LIST, {addAttendee: true});
		}
	};

	const removeItem = (e, data) => {
		if (removeItemHandler) {
			removeItemHandler(data);
			return;
		}
		dispatch(removeAttendeeFromList(data));
	};

	useEffect(async () => {
		setColleaguesStatus();
		if (!attendeeList) return;
		const colleagues = attendeeList?.map((attendee) => attendee?._id);
		try {
			const response = await getColleaguesStatus(colleagues, {
				start: DateTime.fromISO(selectedStartTime).toUTC().toISO(),
				end: DateTime.fromISO(selectedEndTime).toUTC().toISO(),
			});
			setColleaguesStatus(response.data);
		} catch (error) {
			setColleaguesStatus([]);
			console.log(error);
		}
	}, [selectedStartTime, selectedEndTime]);

	function getStatusForThisColleague(colleagueId) {
		if (!colleaguesStatus || colleaguesStatus?.length === 0) return null;
		return colleaguesStatus.find((colleague) => colleague?.entity?._id === colleagueId);
	}

	const renderAttendeeList = () => {
		if (!attendeeList) {
			return <AddPeopleSection clickHandler={goToColleagueList} infoText={t('settings.privacyInformation.shareLocation.searchPeople')} />;
		}

		if (!showHideMore) {
			return (
				<div className="colleagues-container">
					{attendeeList?.map((item, index) => (
						<ColleagueItem
							listLength={attendeeList?.length}
							index={index}
							key={item._id}
							loadingColleagueStatus={!colleaguesStatus}
							colleagueStatus={getStatusForThisColleague(item._id)}
							data={item?.member ? item?.member : item}
							removeItem={removeItem}
						/>
					))}
					<div className="add-more-button" onClick={goToColleagueList}>
						<div className="plus-sign">+</div>
						<p>{t('yourBooking.headers.addMorePeople')}</p>
					</div>
				</div>
			);
		}

		return (
			<>
				<div className={'list-wrapper list-wrapper-show-all' + (showMore ? ' list-wrapper-show-all-opened' : '')}>
					{attendeeList?.map((item, index) => (
						<ColleagueItem
							listLength={attendeeList?.length}
							index={index}
							key={item._id}
							colleaguesStatus={colleaguesStatus}
							data={item?.member ? item?.member : item}
							enableCheck={false}
							checkedIn={item?.checkIn !== undefined}
							invitationResponse={item?.externalStatus}
							hasRoomTypeMSOutlook={hasMSOutlook}
						/>
					))}
				</div>
				{attendeeList?.length > 4 && (
					<p
						className="added-people-show-more"
						onClick={() => {
							setShowMore(!showMore);
						}}>
						{showMore ? (
							t('bookingPage.showLessColleagues')
						) : (
							<>
								{t('bookingPage.showMoreColleagues')}
								<Icon iconName={'angle-down'} cssClass={'angle-down icon-purple'}></Icon>
							</>
						)}
					</p>
				)}
			</>
		);
	};

	return (
		<div className="add-people-section">
			<p className="title">{t('settings.privacyInformation.shareLocation.colleaguesLabel')}</p>
			{showAddColleagueLabel && <p className="subtitle">{t('settings.privacyInformation.shareLocation.addColleagues')}</p>}
			{renderAttendeeList()}
		</div>
	);
}

export default AddPeople;
